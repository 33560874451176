$font-family: 'Qanelas', sans-serif;
$mainColor: #00eb00;
$secondaryColor: #F7A305;
$secondaryDisabledColor: #FCDB9DDD;
$maxWidth: 1920px;
$bodyColor: black;
$headerHeight: 120px;
$bannerHeight: 615px;


@font-face {
  font-family: "Qanelas";
  src: url("./assets/fonts/Qanelas-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Qanelas";
  src: url("./assets/fonts/Qanelas-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Qanelas";
  src: url("./assets/fonts/Qanelas-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: "Qanelas";
  src: url("./assets/fonts/Qanelas-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Qanelas";
  src: url("./assets/fonts/Qanelas-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Qanelas";
  src: url("./assets/fonts/Qanelas-HeavyItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
