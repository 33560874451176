@import "variables";

* {
  font-family: $font-family;
}

html {
  scroll-behavior: auto !important
}

body {
  color: $bodyColor !important;
  font-size: 16px !important;
}

// Payed

.none-payed {
  background-color: red !important;
}

.some-payed {
  background-color: yellow !important;
}

.payed {
  background-color: #C2FDC1 !important;
}

// Headers
%big-header {
  font-size: 80px;
  font-weight: 800;
}

.big-header-white {
  @extend %big-header;
  color: white;
}

.big-header-black {
  @extend %big-header;
  color: black;
  font-style: italic;
  text-transform: uppercase;
}

.section-header-bolder {
  font-size: 24px;
  font-weight: 800;
}

.section-header {
  font-size: 24px;
  font-weight: bold;
}

.light-text {
  color: white;
}

.container-fluid {
  max-width: $maxWidth;
  position: relative;
}

.action-button {
  max-width: 425px;
  width: 100%;
  min-height: 80px !important;
  background-color: $mainColor;
  border: 1px solid #C9C9C9;
  background-clip: content-box;
  text-transform: uppercase !important;
  font-size: 30px;
  font-weight: 800;
  padding: 12px;

  &:hover {
    background-color: #00BC00;
  }

  &:focus {
    outline: none !important;
  }
}

.action-button-inactive {
  opacity: 0.5;
  //background-color: $secondaryDisabledColor !important;
}

.content-link {
  width: 300px;
  height: 60px;
  background-color: $mainColor !important;
  border: 1px solid #C9C9C9 !important;
  background-clip: content-box;
  text-transform: uppercase !important;
  font-size: 22px !important;
  font-weight: 800 !important;
  padding: 6px !important;
}

.content-button {
  width: 300px;
  height: 60px;
  background-color: $mainColor !important;
  border: 1px solid #C9C9C9 !important;
  background-clip: content-box;
  text-transform: uppercase !important;
  font-size: 22px !important;
  font-weight: 800 !important;
  padding: 6px !important;

  &:focus {
    outline: none !important;
  }

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.content-button-small {
  background-color: #00eb00 !important;
  border: 1px solid black !important;
  background-clip: content-box;
  text-transform: uppercase !important;
  font-weight: 800 !important;
  padding: 3px !important;
  font-size: 16px;

  &:focus {
    outline: none !important;
  }

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  span {
    padding: 3px;
  }
}

.content-button-inactive {
  opacity: 0.5;
  //background-color: $secondaryDisabledColor !important;
}

.content-input {
  border-radius: 0 !important;
  border-color: black !important;
}

.search-input {
  border-radius: 0 !important;
  border-color: black !important;
}

.sign-up-form {
  width: 600px;

  .invalid {
    color: red;
  }
}

.password-invalid {
  color: red;
}

.form-control.ng-touched.ng-invalid {
  border-color: red !important;
}

.form-check-input.ng-touched.ng-invalid ~ .form-check-label, .form-check-input.ng-touched.ng-invalid ~ .form-check-label > a {
  color: red !important;
}

.sign-up-summary {
  width: 600px;
}

.link-button {
  cursor: pointer;
}

//
//.big-header-container {
//  border-bottom: 5px solid $secondaryColor;
//  width: 100%;
//}

.sidebar {
  background-color: #1A1A1A;
  color: white;
}

.sidebar-group {
  width: 300px;
}

.sidebar-header {
  font-size: 30px;
  font-weight: 800;
}

.sidebar-item {
  font-size: 20px;
  color: white !important;
  text-decoration: none !important;

  &:hover {
    text-decoration: none;
    color: white !important;
    cursor: pointer;
  }
}

.sidebar-item-active {
  text-decoration: underline !important;
}

.tabs-header-container {
  background-color: $mainColor;
  height: 130px;
  display: flex;
}

.tabs-header {
  text-transform: uppercase;
  font-size: 40px;
  color: black;
  font-weight: 800;
}

.podium-container-header {
  float: right;
  margin-left: auto;
  margin-right: 50px;
  justify-content: center;
}

.tabs-content {
  padding-right: calc(var(--bs-gutter-x) * .5);
}

.tabs-table {
  td {
    padding: 0px
  }

  tr {
    td:first-child {
      font-weight: bold;
    }

    td {
      width: 300px;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background: white;
    }

    tr:nth-child(even) {
      background: white
    }
  }
}

.tabs-table-rankings {
  td {
    border: none;
    text-align: right;
  }

  thead {
    .highlight {
      background-color: $mainColor;
    }

    td {
      text-align: center;
    }
  }

  tbody {
    tr {
      td:first-child {
        font-weight: bold;
        text-align: left;
      }

      td:nth-child(2) {
        width: 250px;
        text-align: left;
      }

      td:nth-child(3), td:nth-child(7) {
        text-align: center;
      }
    }

    border-top: black solid;

    tr:nth-child(odd) {
      .highlight {
        background-color: #C2FDC1;
      }
    }

    tr:nth-child(even) {
      .highlight {
        background-color: $mainColor;
      }
    }
  }
}

.tabs-table-registrations {
  td {
    border: none;
  }

  tbody {
    tr {
      td:first-child {
        font-weight: bold;
      }
    }

    border-top: black solid;
  }
}

.mobile-list-table {
  text-align: center;

  td {
    padding: 0px;
    width: 50%;
  }

  tr {
    td:first-child {
      font-weight: bold;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background: inherit;
    }

    tr:nth-child(even) {
      background: inherit;
    }
  }

  .even {
    background: #C8C8C8
  }
}

.info-table {
  td {
    padding: 0px
  }

  tr {
    td:first-child {
      font-weight: bold;
      width: 150px;
    }
  }
}

.summary-table {
  max-width: 600px;

  td {
    padding: 0px
  }

  tr {
    td:first-child {
      font-weight: bold;
      max-width: 300px;
    }
  }
}

.tabs-superuser-header {
  background-color: black;
  color: white;
}

.sign-up-header {
  font-size: 50px;
  font-weight: 800;
  font-style: italic;
}

button {
  color: black;
}

.postalCode {
  margin-right: 10px;
  width: 100px !important;
  border-radius: 0 !important;
  border-color: black !important;
}

.city {
  border-radius: 0 !important;
  border-color: black !important;
}

.doubleContainer {
}

//.sign-up-small-header {
//  font-size: 20px;
//}

.custom-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

//TODO rmeove???
.custom-table {
  color: $bodyColor !important;

  td {
    border: none !important;
    padding: 0.25em !important;
    width: 50%;
  }
}

.form-check-input {
  border-radius: 0 !important;
  border-color: black !important;
}

//.banner-buttons {
//  margin-top: -75px;
//}

.header-offset {
  margin-top: $headerHeight;
}

//.video-container {
//  height: 75vh;
//  width: 100%;
//  overflow: hidden;
//  margin-top: $headerHeight;
//}
//
//.video {
//  width: 100%;
//  height: 120%;
//  position: relative;
//  top: 50%;
//  left: 50%;
//  transform: translateX(-50%) translateY(-50%);
//  pointer-events: none;
//  z-index: -1;
//}

.content-container {
  max-width: 1410px;
}

a {
  text-decoration: underline !important;
  color: black !important;

  &:hover {
    color: black !important;
  }
}

.subheader {
  font-size: 50px;
  font-weight: 800;
  text-transform: uppercase;
}

.subsection-header {
  font-weight: 800;
  font-size: 40px;
  background-color: $mainColor;
  height: 80px;
  font-style: italic;
  text-transform: uppercase;
}

.sign-up-big-header-green {
  font-size: 160px;
  font-weight: 800;
  font-style: italic;
  color: $mainColor;
}

.sign-up-header-green {
  font-size: 60px;
  font-weight: 800;
  font-style: italic;
  color: $mainColor;
}

.model-image {
  max-width: 100%;
  height: auto;
}

.background {
  background-image: url('/assets/background.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
}

/* Checkbox */
//$form-check-input-width: 25px;
//$form-check-input-height: 25px;
.form-check-input {
  height: 25px !important;
  width: 25px !important;
  margin-top: 0px !important;
}

.form-check-input:checked ~ .form-check-label::before {
  color: black !important;
  border-color: $mainColor !important;
  background-color: $mainColor !important;
}

.form-check .form-check-input {
  margin-left: -25px !important;
}

.form-check-input:checked {
  color: black !important;
  border-color: $mainColor !important;
  background-color: $mainColor !important;
}

.form-check-label::before {
  height: 25px !important;
  width: 25px !important;
}

.form-check-label::after {
  height: 25px !important;
  width: 25px !important;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
}

.form-check-label {
  padding-left: 10px;
}


/* Table */
table > tbody {
  tr:nth-child(odd) {
    background: #C8C8C8;
  }

  tr:nth-child(even) {
    background: white;
  }
}

table > thead {
  font-size: 20px;
  font-weight: 800;
}

table {
  width: 100%;

  td {
    padding: 5px 15px;
  }
}


.td-date {
  width: 10%;
}

.td-name {
  max-width: 35%;
  font-weight: bold;
}

.td-rest {
  width: 15%;
}

.td-link {
  font-weight: 800;
  font-style: italic;
}

.table-list-container {
  width: 75%;
}

.body-text {
  font-size: 20px;
}

/* Pagination */

.page-item.active .page-link {
  background-color: $mainColor !important;
  border-color: $mainColor !important;
  color: black !important;
}

/* Modal */
.modal-footer {
  .btn-primary {
    background-color: $mainColor !important;
    border-color: $mainColor !important;
    color: black !important;

    &:hover {
      background-color: #00d300 !important;
      border-color: #00d300 !important;
    }
  }
}

/* Popover */
.popover-button {
  background-color: $mainColor !important;
  border: none !important;
  color: black !important;
}

@media only screen and (max-width: 767px) {
  .postalCode {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100% !important;
  }

  .doubleContainer {
    flex-direction: column;
  }

  .action-button {
    margin-top: 10px;
  }
}

.spinner-border {
  color: $mainColor !important;
}

//.bigger-body-font-size {
//  font-size: 30px;
//}

@media only screen and (max-width: 1446px) {
  .action-button {
    min-height: 92px !important;
  }

  .sign-up-summary {
    width: auto;
  }
}

@media only screen and (max-width: 1199px) {
  .td-rest {
    width: auto;
  }

  .td-date {
    width: auto;
  }

  .td-name {
    width: auto;
  }


}

@media only screen and (max-width: 1024px) {
  .action-button {
    font-size: 20px;
  }
}

@media only screen and (max-width: 992px) {
  //.video-container {
  //  margin-top: 0;
  //}

  .header-offset {
    margin-top: auto;
  }

  .big-header-white, .big-header-black {
    font-size: 50px;
  }

  .content-input {
    width: 100% !important;
  }

  .table-list-container {
    width: 100%;
  }
}

@media only screen and (max-width: 792px) {
  .sign-up-big-header-green {
    font-size: 120px;
  }

  .sign-up-header-green {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .action-button {
    font-size: 22px;
  }

  .sign-up-big-header-green {
    font-size: 100px;
  }

  .sign-up-header-green {
    font-size: 35px;
  }

  .light-text {
    margin-left: 10px;
    margin-right: 10px;
  }

  .sign-up-form {
    width: auto;
  }

  .podium-container-header {
    float: inherit;
    margin-left: inherit;
  }
}

@media only screen and (max-width: 1280px) {
  .podium-container-header {
    float: inherit;
    margin-left: auto;
    margin-right: auto;
    justify-content: inherit !important;
  }

  .tabs-header-container {
    height: auto;
    flex-direction: column;
  }
}
